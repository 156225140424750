export const deleteUrlParam = (paramName: string, queryParams: URLSearchParams) => {
    queryParams.delete(paramName);
    window.history.replaceState({}, '', window.location.pathname);
};

export const deleteMultipleUrlParams = (paramNames: string[], queryParams: URLSearchParams) => {
    paramNames.forEach((paramName) => {
        deleteUrlParam(paramName, queryParams);
    });
};
